import React from 'react';
import voopterClubMascot from '../../../../assets/images/voopter-club-mascot.png';

import './Banner.css';

function Banner() {
  return (
    <div className="main-banner" >
      <button 
        onClick={() => window.open('https://club.voopter.com.br/?utm_source=voopter-site', '_blank')} 
      >
        <div className="content-banner">
          <p>
            Faça parte do <strong>Voopter Club</strong> e receba achados de viagem no seu email.
          </p>
          <a href="https://club.voopter.com.br/?utm_source=voopter-site" target="_blank" rel="noreferrer">
            Cadastre-se grátis
          </a>
        </div>

        <img 
          src={voopterClubMascot} 
          alt="Voopter Club"
        />
      </button>
    </div>
  );
}

export default Banner;