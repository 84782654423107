import React from 'react';

import './MenuItem.css';

const MenuItem = ({ label, onClick }: { label: string; onClick: () => void }) => (
  <li className="nav-item" onClick={onClick}>
    {label}
  </li>
);

export default MenuItem;
