import React from 'react';
import { FiAlignJustify } from 'react-icons/fi';

import './Button.css';

const Button = ({ onClick }: { onClick: () => void }) => (
  <div className="div-button-mobile-menu" onClick={onClick}>
    <FiAlignJustify className="icon-mobile-menu" />
  </div>
);

export default Button;
