import React, {useEffect} from 'react';

const AdBanner = ({adUnit, width, height, backgroundColor}) => {
  useEffect(() => {
    try {
      window.googletag.cmd.push(function () {
        window.googletag.display(adUnit);
      });
    } catch (error) {
      console.log(error);
    }
  }, [adUnit]);

  return (
    <div
      id={adUnit}
      style={{
        overflow: 'hidden',
        backgroundColor: backgroundColor,
        // width: width,
        // height: height,
      }}></div>
  );
};

export default AdBanner;
