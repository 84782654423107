import React from 'react';
import voopterClubMascot from '../../../../assets/images/voopter-club-mascot.png';

import './MobileBanner.css';

function MobileBanner() {
  return (
    <div className="main-mobile-banner" >
      <div className="content-mobile-banner">
        <div className="description-mobile-banner">
          <p>
            Faça parte do <strong>Voopter Club</strong> e receba achados de viagem no seu email.
          </p>
        </div>

        <img 
          src={voopterClubMascot} 
          alt="Voopter Club"
        />
      </div>

      <button 
        onClick={() => window.open('https://club.voopter.com.br/?utm_source=voopter-site', '_blank')} 
      >
        Cadastre-se grátis
      </button>
    </div>
  );
}

export default MobileBanner;