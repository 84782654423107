import React, {useEffect, useState} from 'react';
import './Switch.css';

import {FiPlus, FiMinus} from 'react-icons/fi';

import Api from '../../../services/api';
import {useDispatch, useSelector} from 'react-redux';
import {OwnProps} from './types';
import {setIsOnewayTrip} from '../../../store/flights';

function Switch({option1, option2, defaultOption, onChange}: OwnProps) {
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = useState(defaultOption);

  useEffect(() => {
    setCurrentValue(defaultOption);
  }, [defaultOption]);

  const getColor = (option: string) => {
    let color: any = {
      backgroundColor: '',
      labelColor: '',
    };

    if (currentValue === option) {
      color.backgroundColor = '#ffffff';
      color.labelColor = '#00688d';

      return color;
    } else {
      color.backgroundColor = 'transparent';
      color.labelColor = '#ffffff';

      return color;
    }
  };

  const onChageValue = (option: string) => {
    setCurrentValue(option);
    onChange(option);
  };

  return (
    <div className={'div-switch'}>
      <button
        style={{
          backgroundColor: getColor(option1).backgroundColor,
        }}
        className={'switch-button-option1'}
        onClick={() => onChageValue(option1)}>
        <span
          style={{
            color: getColor(option1).labelColor,
          }}
          className={'text-switch-button'}>
          {option1}
        </span>
      </button>

      <button
        style={{
          backgroundColor: getColor(option2).backgroundColor,
        }}
        className={'switch-button-option2'}
        onClick={() => onChageValue(option2)}>
        <span
          style={{
            color: getColor(option2).labelColor,
          }}
          className={'text-switch-button'}>
          {option2}
        </span>
      </button>
    </div>
  );
}

Switch.defaultProps = {};

export default Switch;
