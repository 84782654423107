import RadioButton from "../RadioButton";
import { RadioGroupProps } from "./types";

import './RadioGroup.css';

function RadioGroup({ options, onChange, value }: RadioGroupProps) {
  return (
    <div className={'div-radiogroup'}>
      {options.map((option) => (
        <RadioButton
          key={option}
          label={option}
          isChecked={value === option}
          onClick={() => onChange(option)}
        />
      ))}
    </div>
  );
}

export default RadioGroup;