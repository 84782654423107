import React from 'react';
import {Helmet} from 'react-helmet';

function Layout({ children }) {
  return (
    <div>
      <Helmet>
        {/* HTML Meta Tags */}
        <title>
          Encontre Passagens Aéreas Baratas | Voopter - Buscador Gratuito
        </title>
        <meta
          name="description"
          content="Descubra as melhores ofertas de passagens aéreas com o Voopter. Compare preços, encontre passagens aéreas baratas e economize em sua próxima viagem!"
        />

        {/* Google / Search Engine Tags */}
        <meta
          itemprop="name"
          content="Encontre Passagens Aéreas Baratas | Voopter - Buscador Gratuito"
        />
        <meta
          itemprop="description"
          content="Descubra as melhores ofertas de passagens aéreas com o Voopter. Compare preços, encontre passagens aéreas baratas e economize em sua próxima viagem!"
        />
        <meta
          itemprop="image"
          content="http://cloud.voopter.com.br/preview-pages/home-pesquisa.jpg"
        />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://voopter.com.br" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Encontre Passagens Aéreas Baratas | Voopter - Buscador Gratuito"
        />
        <meta
          property="og:description"
          content="Descubra as melhores ofertas de passagens aéreas com o Voopter. Compare preços, encontre passagens aéreas baratas e economize em sua próxima viagem!"
        />
        <meta
          property="og:image"
          content="http://cloud.voopter.com.br/preview-pages/home-pesquisa.jpg"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Encontre Passagens Aéreas Baratas | Voopter - Buscador Gratuito"
        />
        <meta
          name="twitter:description"
          content="Descubra as melhores ofertas de passagens aéreas com o Voopter. Compare preços, encontre passagens aéreas baratas e economize em sua próxima viagem!"
        />
        <meta
          name="twitter:image"
          content="http://cloud.voopter.com.br/preview-pages/home-pesquisa.jpg"
        />
        <link href="./output.css" rel="stylesheet" />
      </Helmet>
      {children}
    </div>
  );
}

export default Layout;
