import React from 'react';
import headerLogo from '../../../../assets/logo-voopter.svg';

import './Logo.css';

const Logo = () => (
  <div className="div-logo">
    <img
      className="div-logo-img"
      src={headerLogo}
      alt="Encontre passagens aéreas baratas com o Voopter"
      onClick={() => window.location.href = window.location.origin}
    />
  </div>
);

export default Logo;
