import React from 'react';
import { FiX } from 'react-icons/fi';
import Menu from '../Menu';

import './MobileMenu.css';
import MobileBanner from '../MobileBanner';

const MobileMenu = ({
  isVisible,
  onClose,
  menuItems,
}: {
  isVisible: boolean;
  onClose: () => void;
  menuItems: { label: string; action: () => void }[];
}) => (
  <div className={`div-mobile-menu ${isVisible ? 'show' : ''}`}>
    {isVisible && (
      <>
        <FiX className="icon-mobile-close-menu" onClick={onClose} />
        <div className="div-mobile-content-menu">
          <Menu items={menuItems} />

          <MobileBanner />
        </div>
      </>
    )}
  </div>
);

export default MobileMenu;
