function getEnvironment() {
  const hostname = window.location.hostname;

  if (hostname === 'voopter.com.br' || hostname === 'new.voopter.com.br') {
    return 'production';
  } else if (hostname === 'staging.voopter.com.br') {
    return 'staging';
  } else if (hostname === 'localhost' || hostname.startsWith('127.0.0.1')) {
    return 'development';
  } else {
    return 'ambiente desconhecido';
  }
}

export {getEnvironment};
