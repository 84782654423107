import React from 'react';
import MenuItem from '../MenuItem';

import './Menu.css';

const Menu = ({ items }: { items: { label: string; action: () => void }[] }) => (
  <ul className="nav-list">
    {items.map((item, index) => (
      <MenuItem 
        key={index} 
        label={item.label} 
        onClick={item.action} 
      />
    ))}
  </ul>
);

export default Menu;
