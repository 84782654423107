import { inlineClickEvent, inlineEventData } from '../types';

export const triggerInlineClickEvent = ({
  inlineClient,
  environmentType,
  searchIntent,
  countryOrigin,
  countryDestination,
}: inlineClickEvent ) => {
  const event: inlineEventData = {
    event: `inline_click-${environmentType}`,
    anunciante: inlineClient,
    IATA_origin: searchIntent?.originAirport,
    IATA_destination: searchIntent?.destinationAirport,
    IATA_route: `${searchIntent?.originAirport}-${searchIntent?.destinationAirport}`,
    country_origin: countryOrigin,
    country_destination: countryDestination,
    departure_date: searchIntent?.departureDate || [],
    return_date: searchIntent?.returnDate || [],
  };

  console.log(event);

  window.dataLayer.push(event);
};
