import 'react-range-slider-input/dist/style.css';
import Icon from '../../Icon';
import './RadioButton.css';
import { OwnProps } from './types';

function RadioButton({
  size,
  label,
  isChecked,
  onClick,
  isBlocked,
  checkedBackgroundColor,
  uncheckedBackgroundColor,
  checkedIconColor,
  unCheckedIconColor,
}: OwnProps) {
  return (
    <div 
      className={'div-principal'}
      onClick={() => !isBlocked && onClick && onClick()}
    >
      <div
        className={'div-radiobutton'}
        style={{
          width: size,
          border: isBlocked ? '2px solid #828282' : '2px solid #00688D',
          backgroundColor: isBlocked
            ? '#828282'
            : isChecked
            ? checkedBackgroundColor
            : uncheckedBackgroundColor,
        }}>
        {isChecked && (
          <Icon
            iconName="check"
            width={size && size - 4}
            iconColor={isChecked ? checkedIconColor : unCheckedIconColor}
          />
        )}
      </div>
      
      {!!label && <span className="label-radiobutton">{label}</span>}
    </div>
  );
}

RadioButton.defaultProps = {
  isChecked: false,
  size: 20,
  checkedBackgroundColor: '#FFFFFF',
  uncheckedBackgroundColor: '#FFFFFF',
  checkedIconColor: '#00688D',
  unCheckedIconColor: '#FFFFFF',
};

export default RadioButton;
