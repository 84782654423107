import {getEnvironment} from './core/helpers/Environments';

const SERVERS = ['flightsearch-api.voopter.com.br'];
let selectedServer: string | null = null;

const checkServerHealthSync = (server: string): boolean => {
  const request = new XMLHttpRequest();
  request.open(
    'GET',
    process.env.REACT_APP_IS_LOCAL === 'true'
      ? `http://${server}/healthcheck`
      : `https://${server}/healthcheck`,
    false,
  ); // false para sincronia
  try {
    request.send(null);
    return request.status === 200;
  } catch (e) {
    return false;
  }
};

const shuffleServers = (
  servers: string[],
  environmentType: string,
): string[] => {
  for (let i = servers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [servers[i], servers[j]] = [servers[j], servers[i]]; // troca de elementos
  }

  return environmentType === 'development'
    ? ['localhost:3000']
    : environmentType === 'staging'
    ? ['flightsearch-api-staging.voopter.com.br']
    : ['flightsearch-api.voopter.com.br'];
};

export const getServer = (): string => {
  if (!selectedServer) {
    const environmentType = getEnvironment();
    console.log('environmentType = ', environmentType);

    const shuffledServers = shuffleServers([...SERVERS], environmentType);

    console.log('environmentType = ', shuffledServers);

    for (const server of shuffledServers) {
      if (checkServerHealthSync(server)) {
        selectedServer = server;
        break;
      }
    }

    if (!selectedServer) {
      throw new Error('Nenhum servidor saudável disponível');
    }
  }
  return selectedServer;
};
